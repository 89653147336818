<template lang="pug">
.navbar.bg-base-100
  .navbar-start
    .dropdown
      label.btn.btn-ghost(tabindex='0' class='lg:hidden')
        svg.h-5.w-5(xmlns='http://www.w3.org/2000/svg' fill='none' viewbox='0 0 24 24' stroke='currentColor')
          path(stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 6h16M4 12h8m-8 6h16')
      ul.menu.menu-sm.dropdown-content.mt-3.p-2.shadow.bg-base-100.rounded-box.w-52(tabindex='0' class='z-[1]')
        li
          a Edit
        li
          a Preview
    .flex.items-center.gap-2 
      .w-6.h-6.rounded(:style='colorStyle')
      div(class="text-sm font-medium breadcrumbs")
        ul
          li 
            router-link(to='/page') My Pages
          li(class='text-[#64748B]') {{ page?.attributes.Title }}
          //- li(class='text-[#64748B]') Prospecting
  .navbar-center.hidden(class='lg:flex')
    Tabs(v-model='tab')
      TabsList.rounded-lg
        TabsTrigger.tab-trigger-custom(value='edit') Edit
        TabsTrigger.tab-trigger-custom(value='preview') Preview

    //- ul.menu.menu-horizontal.px-1
    //-   li
    //-     a Edit
    //-   li
    //-     a Preview
  .navbar-end.flex.items-center
    Popover
      client-only
        PopoverTrigger(as='div')
          UButton(class='gap-2 text-white transition-all w-max active:scale-95' @click='')
            .flex.gap-2
              Icon.w-4.h-4(name='lucide:link')
              .font-semibold Get link
      PopoverContent(class='w-[480px] px-0 z-[101]' :side-offset="10")
        div
          EditLayoutGetLink

    button.text-white.gap-2.w-max.bg-base-100.ml-4(@click='openRequired')
      Icon.w-4.h-4.text-black(name='lucide:lock')


    button.text-white.gap-2.w-max.bg-base-100.ml-4(class='' @click='')
      Icon.w-4.h-4.text-black(name='lucide:more-horizontal')

</template>

<script setup lang="ts">
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
const route = useRoute()
const UButton = defineAsyncComponent(
  () => import('@/components/ui/button/Button.vue'),
)
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

const { tab } = useEditTab()
const { openRequired } = useRequiredField()

const { page } = useAccessPage()

const { colorStyle } = useBrandColor()
</script>
<style lang="stylus" scoped>
.tab-trigger-custom[data-state='active']
  color black
  border-radius 6px
</style>
